import React from 'react';
import { Router } from "@reach/router"
import Layout from '../components/layout';
import PrivateRoute from '../components/privateRoute';
import Login from '../components/login';

function App() {
  return (
    <Layout>
      <Router>
        <PrivateRoute path="/muzieklijst" />
        <Login path="/app/login" />
      </Router>
    </Layout>
  );
}
export default App;
